import 'focus-visible';
import App from '@/App';
import { auth } from '@/stores/session';
import { getcookie } from '@/utils/cookie';

// Init auth state off back of OAuth2 handshake.
const cookie = getcookie('auth');
if (cookie.length) {
  auth.set(cookie);
  // Expire cookie -- we can now resort to localStorage.
  document.cookie = 'auth=;path=/;max-age=0';
}

const app = new App({
  target: document.body,
});

export default app;
